<template>
  <CustomTableInvoices
    style="min-width: 100%; max-width: 100px"
    :tableName="tableName"
    :showStatus="false"
    :list="list"
    :pageProp="page"
    :pages="pages"
    :itemsPerPage="itemsPerPage"
    :totalItems="totalItems"
    :filterStatus="filterStatus"
    :showDownloadBtn="true"
    :loading="loading"
    @updateFetchPage="updateFetchPage"
    @paginatorHandler="paginatorHandler"
    @updateStatus="updateStatus"
    @queryForOccurences="queryForOccurences"
    @downloadPdf="downloadPdf"
  ></CustomTableInvoices>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import CustomTableInvoices from "@/components/customTables/CustomTableInvoices";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import store from "@/store";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Faturas",
  components: {
    CustomTableInvoices,
  },
  data() {
    return {
      tableName: "Detalhe",
      page: 1,
      pages: 0,
      list: [],
      itemsPerPage: 12,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
      filterStatus: [
        "Factura",
        "Fatura-Recibo",
        "Nota de Crédito",
        "Nota de Crédito P",
        "Nota de Débito",
      ],
      filterStatusEti: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    updateFetchPage(page, pagesSize) {
      this.page = page;
      this.itemsPerPage = pagesSize;
      this.loadInvoices(page);
    },

    queryForOccurences(searchInput) {
      this.searchInput = searchInput.searchInput;
      this.startDate = searchInput.startDate;
      this.endDate = searchInput.endDate;

      this.paginatorHandler(1);
    },

    /*
     * ------------------DATA MANIPULATION------------------
     */
    paginatorHandler(page) {
      this.page = page;
      this.loadInvoices(page);
    },
    //filters by status, update pages, and list to show
    updateStatus(status) {
      //get status string and iterate stausEti to get key
      if (status != "Todos" && status.trim() != "") {
        this.status = this.filterStatusEti.find(
          (item) => item.strDescricao === status
        ).strAbreviatura;
      } else {
        this.status = status;
      }
      this.paginatorHandler(1);
    },
    MapDocTypes(docTypes) {
      this.filterStatus = docTypes.map((docType) => {
        return docType.strDescricao;
      });
    },

    /*
     * ------------------API FETCHING------------------
     */
    loadInvoices(page) {
      this.loading = true;
      let queryString = this.getQueryString(page);

      ApiService.get("vendas", queryString)
        .then((response) => {
          this.totalItems = response.data.TotalPages;
          this.pages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.list = response.data.Data;
          this.loading = false;
          console.log(this.list)
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    loadDocTypes() {
      if (
        !store.getters.currentAuthUser.acessoAreaFinanceira &&
        store.getters.currentAuthUser.acessoAreaComercial
      ) {
        console.log("ADDING GUIA DE REMESSA TO FILTERS")
        this.filterStatusEti = {
          strAbreviatura: "CGRMM",
          strDescricao: "GUIA REMESSA",
        };
        this.filterStatus = this.filterStatusEti;
        return;
      }
      ApiService.get("Utils/tiposDocs")
        .then(({ data }) => {
          this.filterStatusEti = data.Data;
          this.MapDocTypes(data.Data);
        })
        .catch(({ data }) => {
          console.log(data);
        });
    },

    downloadPdf(docId) {
      //call api, get file in b64 format, and download it
      ApiService.get("Utils/document/" + docId).then((response) => {
        let file = response.data.Data.docBase64;
        let fileName = response.data.Data.docName;
        const parts = fileName.split(".");
        let fileExtension = parts[parts.length - 1];
        let mimeType = "application/pdf";
        if (fileExtension === "xml") {
          mimeType = "application/xml";
        }
        //if txt
        else if (fileExtension === "txt") {
          mimeType = "text/plain";
        }

        let byteCharacters = atob(file);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: mimeType });

        // Create a URL for the Blob
        let fileURL = URL.createObjectURL(blob);

        // Open the file in a new tab
        let newTab = window.open(fileURL, "_blank");
        if (newTab) {
          newTab.document.title = fileName;
        } else {
          // Opening the new tab was blocked by the browser
          console.error("Opening the file was blocked by the browser.");
          window.location.href = fileURL;
        }
      });
    },
    /*
     * --------------------UTILS--------------------
     */
    getQueryString(page) {
      let queryString = `?pageNumber=${page}&pageSize=${this.itemsPerPage}&intCodEntidade=${store.getters.currentUser.numcl}`;

      if (this.startDate) {
        queryString += `&DtmInicial=${this.startDate}`;
      }
      if (this.endDate) {
        queryString += `&DtmFinal=${this.endDate}`;
      }
      if (
        !store.getters.currentAuthUser.acessoAreaFinanceira &&
        store.getters.currentAuthUser.acessoAreaComercial
      ) {
        this.status = "CGRMM";
        queryString += `&StrAbrevTpDoc=${this.status}`;
      } else {
        if (this.status) {
          queryString += `&StrAbrevTpDoc=${this.status}`;
        }
      }
      if (this.searchInput) {
        queryString += `&strNumero=${this.searchInput}`;
      }

      return queryString;
    },
  },
  mounted() {
    setCurrentPageBreadcrumbs("Faturação");
    //only load from last year at first
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toISOString().substring(0, 10);
    this.loadInvoices(1);
    this.loadDocTypes();
  },
});
</script>
